.CreateSite-modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .CreateSite-modalContainer {
      width: 500px;
      min-height: 60vh;
      border-radius: 12px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      padding: 25px;
    /* background: #4794a8;  */
    background: white;
    /* justify-content: start; */
  
      ;
  }
  
  .CreateSite-modalContainer .title {
      display: inline-block;
      text-align: center;
      margin-top: 10px;
      color: #022e35;
      font-family: 'Cabin';
  }
  
  .CreateSite-titleCloseBtn {
      display: flex;
      justify-content: flex-end;
      
  }
  
  .CreateSite-titleCloseBtn button {
      background-color: transparent;
      border: none;
      font-size: 25px;
      cursor: pointer;
      /* color: red; */
  }
  
  .CreateSite-modalContainer .Create-body {
      flex: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.7rem;
      text-align: center;
      overflow-y: auto;
      background: white;
      
  }
  
  .create-site-name {
   width: 311px;
   height: 46px;
   background: #FFFFFF;
   border: 1px solid #F0F0F0;
   border-radius: 10px;
   padding:10px;
}

.create-modal-label{
   font-family: 'Cabin';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 22px;
   color: #000000; 
}

.create-form{
   display: flex;
   flex-direction: column;
   /* align-items: center; */
   /* background-color: red; */
   /* justify-content: start; */
   min-height: 100%;
}