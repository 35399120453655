.Individual-button-group {
    /* width: 100%; */
    display: flex;
    align-items: center;
    background: #FFFFFF;
    padding: 10px;
    border: 1px solid #EAECF4;
}

.Individual-Analysis-button-1 {
    margin-left: auto;
    width: 254px;
    height: 40px;
    color: white;
    background: #0B7209;
    border-radius: 10px;

}

.Individual-Analysis-button-2 {
    margin-left: 20px;
    width: 254px;
    height: 40px;
    background: #0B7209;
    border-radius: 10px;
    color: white;
}

.Individual-Analysis-component {
    display: flex;
    flex-direction: column;
}

.Individual-Analysis-google-map {
    width: 1008px;
    height: 309px;
    background: #F3F3F3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.Individual-Analysis-information {
    width: 1008px;
    background: #F3F3F3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: 30px;
    padding: 20px;

    background: rgba(49, 98, 226, 0.06);
    border-radius: 10px;
}

.Analysis-name {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    /* identical to box height */
    color: #000000;
}

.Analysis-desc {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.Individual-Analysis-button {
    width: 133px;
    height: 40px;
    background: #0B7209;
    border-radius: 10px;
    color: white;
}