.Analysis-modalBackground {
 width: 100vw;
  height: 100vh;
  background-color: transparent;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.Analysis-modalContainer {
    width: 500px;
    height: 80vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  /* background: #4794a8;  */
  background: white
    ;
}

.Analysis-modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    color: #022e35;
    font-family: 'Cabin';
}

.Analysis-titleCloseBtn {
    display: flex;
    justify-content: flex-end;
    
}

.Analysis-titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
    /* color: red; */
}

.Analysis-modalContainer .Analyisis-body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
    overflow-y: auto;
    background: white;
}
