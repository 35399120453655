.Dashboard--site{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 15px;
}

.Dashboard--title{
    font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 34px;
/* identical to box height */
color: #000000;
margin: 20px;
}

.Dashboard--image{
    width: 900px;
   height: 512px;
}
