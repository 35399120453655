.Site-setting-container {
    width: 72.5%;
    height: 84vh;
    flex-direction: column;
    background: #FFFFFF;
    border: .011px solid #FFFFFF;
    border-radius: 10px;
    display: flex;
    /* align-items: center; */
    margin-left: auto;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 20px;
}

.Site-setting-heading {
    font-family: 'Cabin';
    font-style: normal;
    /* font-weight: 700; */
    /* font-size: 22px; */
    line-height: 27px;
    color: #000000;

}

.Site-setting-id {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;

}

.Site-setting-message {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #BEE7E8;

}

.Site-setting-owner {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
}

.Site-setting-name {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;

}

.Site-setting-site-name {
    width: 311px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    padding: 20px;
}

.first-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.city1 {
    margin: 10px;
    display: flex;
    flex-direction: column;
}

.city1-label {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.city-input {
    width: 311px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 10px;
}

.comparingImage {
    margin-top: 15px;
}

.distance {
    display: block;

    /* width: 100px; */
    height: 22px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-left: 10px;
    color: #000000;
}

.private {
    display: block;
    margin: 10px;

    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #000000;
}

.private-desc {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    margin: 10px;
    color: #000000;

}

.site-toggle-button {
    margin: 10px;
}

.site-toggle-button--text {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #3498DB;
}

.save--button {
    width: 155px;
    height: 40px;
    color: white;
    margin: 10px;
    background: #9cc00c;
    border: none;
    border-radius: 10px;
}