body{
    overflow: hidden;
}
.dashboard-site-component{
    display: flex;
    flex-direction: column;
}

.dashboard-google-map{
width: 1008px;
height: 309px;
background: #F3F3F3;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
margin-left: auto;
margin-right: 30px;
margin-top: 30px;
margin-bottom: 30px;
}

.dashboard-site-information{
    width: 72.5%;
height: 100px;
background: #FFFFFF;
border-radius: 10px;
margin-left: auto;
margin-right: 30px;
margin-top: 20px;
}

.dashboard-information-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 70px;
}

.dashboard-card-space{
    background: #FFFFFF;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 74vh;
}

.dashboard-analysis-button{
    width: 155px;
    height: 33px;
    
    background: #f1143b;
    border-radius: 8px;
    border: none; 
    color: white;
}

.h5{
    color: #022e35;
}
button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}