body {
    position: relative;
}

.Sidebar {
    background: #195E79;
    border-radius: 0px 20px 20px 0px;
    width: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
}

.Sidebar--container {
    width: 100px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.Sidebar--list {
    list-style-type: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.Sidebar--item {
    display: flex;
    flex-direction: column;
    margin: 10px;
    cursor: pointer;
    color: white;
}

.Sidebar--item.active {
    color: #B7FFB7;
}

.Sidebar--open-icon {
    padding: 20px;
    cursor: pointer;
}