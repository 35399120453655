
.UserMenu--container{
    width:23vw;
    background: #317E9C;
    border-radius: 0px 0px 20px 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
}

.UserMenu--logo{
    margin-left: auto;
    margin-right: 30px;
    margin-top: 20px;
    width: 173px;
    height: 60px;
    margin-bottom: 50px;
}

.UserMenu--text{
    margin-left: auto;
    margin-right: 130px;
    margin-top: 30px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    
    color: #FFFFFF;
    
}

.UserMenu--parameter{
    padding-top: 0px;
    margin-left: 220px;
    margin-right: 120px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-align:start;
    padding: 10px;
    background: #317E9C;
    width: 14vw;
    height: 40px;
    color: #E9E9E9;
    cursor: pointer;
}

.UserMenu--parameter.active{
    background: #195E79;
}

.UserMenuu-delete-parameter{
    margin-top: auto;
    margin-bottom: 30px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    margin-right: 30px;
    cursor: pointer;
    color: #F75D59;
    
}



