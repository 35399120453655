.Analysis--display{
    display: flex;
    flex-direction: column;
    width: 94vw;
    background-color: aliceblue;
    margin-left: auto;
    overflow-y: scroll;
    height: 680px;
}

.Analysis-display-intial{
   display: flex;
   flex-direction: column;
   width: 80%;
   margin-left: auto;
}

.heading-display-download-button{
    /* min-width: 400px; */
    height: 45px;
    background: #f1143b;
    border-radius: 8px;
    border: none; 
    color: white;
    margin-right: 20px;
    font-size: 15px;
    padding-right: 20px;
    padding-top: 6px;
    font-weight: 600;
}  

.heading-display{
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.heading-extra{
    background: #f1143b;
    border-radius: 8px;
    border: none; 
    color: white;
    max-width:210px;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
}

.heading-button-group{
    display: flex;
    justify-content:center;
    /* background-color: black; */
    margin-right: 20px;
    /* padding: 10px; */
}


.histogram-label{
    color: #022E35;
    font-family: 'cabin';
}

.card2 {
    margin-bottom: 15px;
    background: #fff;
    border: 0.25px solid #E8E8E8;
}

.card-body {
    background: #fff;
}