.dashboard-city-card{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 213px;
height: 51px;
border-radius: 10px;
margin: 10px;
background: #F7F7FF;
border: 1px solid #778ECA;
border-radius: 10px;
cursor: pointer;    
}

.dashboard-city-card.active{
    background: #317E9C; 
    color: white;
}

.city--image{
width: 32px;
height: 32px;
}

.cityName{
    font-family: 'Cabin';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 19px;

color:black;
}