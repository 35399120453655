* {
    box-sizing: border-box;
}

.navbar--container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 72px;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-bottom: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
}

.navbar--heading {
    margin-left: 44%;
    margin-right: auto;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 43px;
    color: #0D276B;
    text-decoration: none;
}

.navbar-user-details {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 24%;
}

.navbar-username {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;

    color: #195E79;

}

.navbar-user-route {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;

    color: #B4B0BF;
    margin-top: 10px;
}

.navbar-site-details {
    /* width: 90px; */
    /* height: 40px; */
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* background: #BEE7E8; */
    border: 1px solid #F0F0F0;
    border-radius: 10px;
    margin-left: auto;
}

.navbar-site-name{
    font-family: 'Cabin';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 19px;

color: #808080;
}

.navbar-sitename-change{
    font-family: 'Cabin';
font-style: normal;
font-weight: 600;
font-size: 16px;
color: #022e35;
margin: 10px;
cursor: pointer;
}
.navbar--features {
    display: flex;
    min-width: 180px;
    justify-content: space-around;
    align-items: center;
}

.navbar--setting-logo {
    background-color: red;
    border: 2px solid #716571;
}