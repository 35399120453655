.Analysis-Menu--card{
    padding: 10px;
    width: 100%;
   height: 120px;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 10px; 
   margin-bottom: auto;
   font-size: 20px;
  
}
.label-group{
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: center;

}

.Analysis-card-data{
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    text-align: left;
}

.Analysis-card-data-M{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
}
.Analysis-card-data-year{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
}

.Analysis-card-M-special{
    display: flex;
    align-items: center;
    /* margin-left: auto ; */
    justify-content: center;
    width: 200px;
}

.Analysis-card-year-special{
    display: flex;
    align-items: center;
    /* margin-left: auto ; */
    justify-content: center;
    width: 200px
}

.Analysis-data-parameter{
    font-family: 'Cabin';
font-style: normal;
/* font-weight: 700; */
font-size: 16px;
line-height: 19px;
color: #022e35;
}

.Analysis-data-parameter-m{
    font-family: 'Cabin';
font-style: normal;
/* font-weight: 700; */
font-size: 16px;
line-height: 19px;
color: #022e35;
padding-top: 15px;
}

.Analysis-input-parameter-M{
  
}

.Analysis-input-parameter-M-label{
    font-size: 16px;
    margin: 5px;
}

.Analysis-input-parameter-name{
    width: 200px;
    height: 25px;
    left: calc(50% - 75px/2 - 402.5px);
    top: calc(50% - 25px/2 - 261px);
    
    background: #518ce4;
    border-radius: 4px;
    color: white;
    outline: none;
    text-indent: 10px;
    font-size: 16px;
    border: none;
}

.Analysis-input-parameter{
    width: 100px;
height: 25px;
left: calc(50% - 75px/2 - 402.5px);
top: calc(50% - 25px/2 - 261px);

background: #518ce4;
border-radius: 4px;
color: white;
outline: none;
text-indent: 10px;
font-size: 16px;
border: none;
}

.Analysis-slidecontainer{
    width: 100%;
    background-color: transparent;
}

.Analysis-slider{
    width: 100%; 
    height: 25px;
    background-color: transparent;
    border-radius: 60px;
    outline: none;
    opacity: 0.7; 
    transition: .2s; 
    transition: opacity .2s;
}

.Analysis-slider:hover {
    opacity: 1;
  }
  
  .Analysis-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border-radius: 60px;
    cursor: pointer;
  }

.Analysis-RangeValue{
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.Analysis-RangeValue_data{
 font-family: 'Cabin';
font-style: normal;
font-weight: 200;
font-size: 12px;
/* line-height: 19px; */
text-align: right;
color: #56585a;
}


