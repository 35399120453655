
.Dash-Menu--container{
    width:23vw;
    background: #4794a8;
    border-radius: 0px 0px 20px 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
}

.Dash-Menu--logo{
    margin-left: auto;
    margin-right: 30px;
    margin-top: 20px;
    width: 173px;
    height: 60px;
}

.Dash-Menu--text{
    margin-left: auto;
    margin-right: 70px;
    margin-top: 30px;
    font-family: 'Cabin';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 29px;

color: #FFFFFF;
}

.Dash-Menu--parameter{
    margin-left: auto;
    margin-right: 20px;
    font-family: 'Cabin';
    font-style: normal;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    color: #FFF;
}


.dashmenu-input{
    margin-left: auto;
    min-width: 15vw;
    margin-top: 20px;
    overflow-y: auto;
}

.input-data-label{
    color: white;
}
.label-heading{
    margin: 10px;
    font-size: 14px;
    color: #E6FFE6;
}

