.data-management-container {
    background: #BEE7E8;
    max-height: 900px;
    overflow-y: auto;
}

.data-management-extraspace {
    display: flex;
    background: #FFFFFF;
    padding: 10px;
    border: 1px solid #EAECF4;
    height: 50px;
}

.datamangement-workpace {
    width: 72.5%;
    min-height: 1150px;
    flex-direction: column;
    background: #FFFFFF;
    border: .011px solid #FFFFFF;
    border-radius: 10px;
    display: flex;
    /* align-items: center; */
    margin-left: auto;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 20px;
   
}

.data-main-heading {
    font-family: 'Cabin';
    font-style: normal;
    line-height: 27px;
    color: #000000;
    margin-top: 6px;
}

