.data-management-uploading-card {
    display: flex;
    flex-direction: column;
    min-height: 170px;
}

.label-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-labels {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.label-heading-text {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 15px;
    color: #000000;
    text-align: start;
}

.label-desc {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
}

.card-download {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* width: 100px; */
    cursor: pointer;
    min-width: 200px;
}

.download--logo {
    margin-bottom: 13px;
    margin-right: 8px;
}

.download-text {
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #0D276B;
}

.data-uploading{
    background: #F3F3F3;
border-radius: 10px;
width: 100%;
height: 80px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top: 10px;
}

.data-management-description{
    font-family: 'Cabin';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
color: #808080;
}

.download-data{
    min-width: 150px;
    margin: 10px auto;
    height: 40px;
    color: white;
    background: #9cc00c;
    border: none;
    border-radius: 10px;
}