.Menu--card{
    margin-left: auto;
    margin-right: 10px;
    padding: 10px;
    /* width: 232px; */
    width: 15vw;
   height: 90px;
   background: #195E79;
   border-radius: 10px;
   display: flex;
   align-items: center;
   justify-content: space-around;
   margin-top: 10px;
   border: 1px solid #778ECA;
    border-radius: 10px;
}

.card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #195E79;
    border: none;
}

.card--img{
    width: 32px;
    height: 32px;
}

.card--heading{
    font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;

color: #FFFFFF;
margin: 5px;
}

.Menu-card--data{
    
font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 49px;
/* identical to box height */

text-align: right;

color: #FFFFFF;
}
