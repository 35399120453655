.addmodalBackground {
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 40px;
    margin-left: 40%; */
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.addmodalContainer {
    width: 500px;
    height: 700px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    overflow-y: auto;
}

.addmodalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
}

.addtitleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.addtitleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.addmodalContainer .addbody {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
}

.addmodalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

#cancelBtn {
    background-color: crimson;
}

.adduserform {
    display: flex;
    flex-direction: column;
}

.adduserform--label {
    font-size:15px;
    text-align: left;

}

.adduserform-input-text {
    outline: none;
    border: 1px solid black;
    border-radius: 10px;
    text-indent: 10px;
    margin-bottom: 10px;
    font-family: 'cabin';
    font-size: 20px;
    width: 300px;
    height: 50px;
}

.adduserform--label-radio {
    font-size: 15px;
    text-align: left;
    margin-bottom: 2px;
    margin-left: 5px;
}


.adduser-permissiontype {
    display: flex;
    align-items: center;
}

.addusersubmit {
    width: 200px;
    height: 50px;
    margin: 10px auto;
    background: #0B7209;
    border-radius: 4px;
    color: white;
}