
.Analysis-Menu--container{
    width:380px;
    background: #317E9C;
    border-radius: 0px 0px 20px 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    overflow-y: auto;
}

/* ::-webkit-scrollbar {
    width: 8px;
  } */

.Analysis-Menu--logo{
    margin-left: auto;
    margin-right: 30px;
    margin-top: 20px;
    width: 173px;
    height: 60px;
}

.Analysis-Menu--text{
    margin-left: auto;
    margin-right: 120px;
    margin-top: 30px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    
    color: #FFFFFF;
    
}

.Analysis-Menu--parameter{
    padding-top: 0px;
    margin-left: auto;
    margin-right: 55px;
    font-family: 'Cabin';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 10px; */
    /* identical to box height */
    
    color: #E9E9E9;
}

.Analysis-button{
    width: 200px;
height: 42px;
margin-top: 30px;
margin-left: auto;
margin-bottom: 50px;
margin-right: 20px;

background: #9cc00c;
border-radius: 10px;
border: none;
color: white;
}

