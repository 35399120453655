.dashboard--form{
   display: flex;
   flex-direction:column;
   align-items: center;
   /* justify-content: center; */
   width: 800px;
   margin: 0 auto;
   height: 90vh;
}

.dashboard--loader{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
 width: 50px;
 height: 50px;
}

.dashboard--label{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    padding-left: 23px;
    padding-right: 20px;
}

.dashboard-lable-1{
    
font-family: 'Cabin';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
/* identical to box height */
color: #022e35;
}


.dashboard-label-2{
    font-family: 'Cabin';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #0D276B;
cursor: pointer;
}

.dashboard--input{
    background: #FFFFFF;
border: 1px solid #F0F0F0;
border-radius: 10px;
width: 94%;
height: 50px;
text-indent: 10px;
}

.dashboard--button-section{
    width: 94%;
    display: flex;
    align-items:center;
    justify-content: space-evenly;
    margin: 10px;
}

.dashboard-button{
    background: #F3F3FF;
    width: 50%;
    height: 50px;
    border-color: #0949ad;
}

.dashboard-button.active{
    background: #0949ad;
    color: white;
    border-color: #0949ad;
}

.dashboard--filtre{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
}

.dashboard--filtre--label{
    font-family: 'Cabin';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #828282;
}

.dashboard--filtre--label-button{
    font-family: 'Cabin';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;

color: #828282;
}

.dashboard--filtre--label-button.active{
    color: #0D276B;

}

.dashboard-input-city{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* padding: 20px; */
    justify-content: space-evenly;
    
}


.dashboard-end-button{
    margin-top: auto;
    margin-bottom: 20px;
    width: 94%;
height: 42px;
left: calc(50% - 679px/2 + 44.5px);
top: calc(50% - 42px/2 + 427.5px);
background: #0B7209;
border-radius: 10px;
color: white;
}