.dashboard-information-show{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;    
    margin-left: auto;
    border: 1px solid #EAECF4;
}
.dashboard-infomation-data{
    font-family: 'Cabin';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #BEE7E8;
display: flex;
align-items: center;
justify-content: space-between ;
height: 40px;
min-width: 150px;
}

.dashboard-infomation-data-name{
    font-family: 'Cabin';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #BEE7E8;
display: flex;
align-items: center;
justify-content: center ;
height: 40px;

min-width: 150px;
;
}

.dashboard-infomation-data-1{
    font-family: 'Cabin';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;

color: #000000;
display: flex;
align-items: center;
justify-content: center ;
height: 40px;
/* width: 10vw; */
cursor: pointer;
display: flex;
align-items: center;
justify-content: space-between;
}


.dashboard-statusimg{
    width: 20px;
    height: 20px;
}

.dashboard-text{
    text-align: start;
    /* width: 8vw; */
    padding-left: 10px;
    color: #022e35;
    min-width: 200px;
}