.userManegemnet-container{
    background: #BEE7E8;
    max-height: 90vh ;
    overflow-y: auto;
}

.UserManagement-button {
    /* width: 100%; */
    display: flex;
    align-items: center;
    background: #FFFFFF;
    padding: 10px;
    border: 1px solid #EAECF4;
}

.Add-new-user-button {
    margin-left: auto;
    color: white;
    background: #F1143B;
    border-radius: 10px;
    border: none;
    width: 155px;
    height: 40px;
}

.Userlist-Component{
    max-width: 900px;
    /* min-height: 309px; */
    flex-direction: column; 
    background: #FFFFFF;
    border: .011px solid #FFFFFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.user-action-button{
    color: white;
    background: #F1143B;
    border-radius: 10px;
    border: none;
    min-width: 50px;
    height: 40px; 
}
.Userlist-label{
 display: flex;
 align-items: center;
 justify-content: space-between;
 padding: 20px;
 width: 100%;
 
}

.list-name{
    font-family: 'Cabin';
font-style: normal;
/* font-weight: 700; */
/* font-size: 22px; */
line-height: 27px;
/* identical to box height */
color: #000000;
margin: 10px;

}
.list-button-group{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.list-delete-button{
    width: 120px;
height: 40px;

background: #FFFFFF;
border: 1px solid #E4E4E4;
border-radius: 10px;
margin-right: 5px;
}
.button-download-image{
    padding: 10px;
}

.extra-button{
    width: 55px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
}

.User-table-component th{
font-family: 'Cabin';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 17px;
/* identical to box height */
color: #B3B3B3;
}

.User-table-component{
  /* min-height: 500px; */
  max-width: 900px;
  /* background-color: red; */
}

.mytable{
    min-width: 900px;
    max-width: 900px;
    padding: 20px;
   
}

.mytable th,tr,td{
    border: none;
}

.checkbox-input{
    display: flex;
    align-items:center;
    margin-top: 7px;
}

.User-Description{
    max-width: 900px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 30px;
    padding: 2  0px;
    border-radius: 20px;
}

.desc-heading{
    font-family: 'Cabin';
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;

color: #363636;


}

.desc-paragraph{
    font-family: 'Cabin';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
/* identical to box height */

text-align: justify;
color: #585858;
}
